<template>
    <div>
        <!-- Mobile Map -->
        <div class="collapse" id="collapseMap">
            <div class="mapColapse">
                <div ref="mob_map" style="height:400px"></div>
            </div>
        </div>
        <section class="py-0">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-8 col-lg-9 col-12 py-4">
                        <SelectedFilters />
                        <div class="row">
                            <div class="col-lg-3 mb-0 d-none d-lg-block filters_sidebar">
                                <Filters />
                            </div>
                            <div class="col-lg-9 col-12">
                               <BusinessListing />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-3 col-md-3 mb-0 d-none d-lg-block position-relative">
                        <!-- Laptop Map -->
                        <div class="location-map">
                            <div ref="map" style="height: calc(100vh - 67px)"></div>
                            <div id="customWindow" class="custom-window" style="z-index: 10000"></div>
                            <div id="customWindowArrow" class="custom-window-arrow"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
    import $ from 'jquery'
    import { ref, onMounted } from 'vue'
    import SelectedFilters from '@/components/search/SelectedFilters.vue'
    import Filters from '@/components/search/Filters.vue'
    import BusinessListing from '@/components/search/BusinessListing.vue'
    import { useSearchStore } from '@/stores/search'
    import { useRoute } from 'vue-router'
	const store = useSearchStore()
    const route = useRoute()
    const map = ref(null);
    const mob_map = ref(null);

    
    onMounted(() => {
        // Display Map
        let mapOptions = {
            center: {lat: 35.2270000, lng: -80.8460000},
            zoomControl:true,
            zoomControlOptions:{
                position: window.google.maps.ControlPosition.LEFT_TOP
            },
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            styles: [
                {
                    featureType: 'poi',
                    stylers: [{visibility: "off"}]
                }
            ]
        }

        if (window.screen.width > 991) {
            mapOptions.zoom = 13
            store.map = new window.google.maps.Map(map.value, mapOptions)
        }else{
            mapOptions.zoom = 10
            store.map = new window.google.maps.Map(mob_map.value, mapOptions)
        }

        // Get Businesses
        store.getBusinesses(route.params)
        // console.log(store.businessList)

        // Add hover effect on info window to stay appear when mouse is over
        $('#customWindow').mouseleave(function() {
            store.hideInfoWindow()
        });

        $('#customWindowArrow').mouseover(function() {
            store.displayInfoWindow()
        });
    })
</script>

<style scoped>

  .carousel-control-next-icon {
        background-color: #fff;
        border-radius: 50%;
        background-image: url("./../../assets/images/chevron-right-solid.svg");
        width: 20px;
        height: 20px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 50% ;
        /* opacity: 0.7; */
    }
    .carousel-control-prev-icon{
        background-color: #fff;
        border-radius: 50%;
        background-image: url("./../../assets/images/chevron-left-solid.svg");
        width: 20px;
        height: 20px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 50% ;
        opacity: 0.01;
    }
    .allTitles{
        color: #1c1c1c !important;
    }
    .salon-name h4 {
        color: #1c1c1c;
        font-size: 1.3rem;
    }
    .carousel-control-prev-icon:hover{
        color: #fff;
        opacity: 1;
    }
    .carousel-control-prev{
        background: none;
    }
    .location-map .result-box{
        margin: 0;
        border: none;
        width: 270px
    }
    hr.faded {
        border-top: 1px solid #d1d1d1
    }
    .fa-location-arrow {
        color: #cdcdcd
    }
    .results-cont h3{
        font-weight: 600;
        color: #1b1b1c
    }
    .est-time ,.opening-status{
        color: #0c8f3a;
        font-weight: 600;
    }
    .send_a_text{
        text-transform: none !important;
        padding: 10px 25px !important
    }
    .not_found li::marker{
        color: #8d8e91;
        font-size: 1em
    }
    .justify-content-between{
        margin-top: 75px
    }
    .gm-ui-hover-effect{
        top: -1px !important;
        right: 2px !important
    }
    .custom-window {
        position: absolute;
        /*width: 300px;
        height: 107px;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: none;
        top: 0;
        left: 0;
        box-shadow: 2px 5px 10px 3px #888888;
        padding-left: 10px;
        padding-right: 10px;*/
    }

    .custom-window-arrow {
        display: none;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #FFFFFF;
        position: absolute;
        top: 0;
        left: 0;
    }
    .filters_sidebar{
        position: sticky;
        bottom: 0;
        align-self: flex-end
    }
    @media (min-width:768px) {
        .iamges-carousel .carousel__prev, .iamges-carousel .carousel__next{
            display: none
        }
        .iamge-carousel:hover .iamges-carousel .carousel__prev, .iamge-carousel:hover .iamges-carousel .carousel__next{
            display: block
        }
    }
    @media (max-width:575px) {
        .mob1-search input{
            background-image: none !important
        }
        .left_icon{
            position: absolute;
            left: 30px;
            top: 21px;
            z-index: 1;
            font-size: 25px
        }
        .gm-style .gm-style-iw-d{
            overflow: auto !important
        }
        .gm-style .gm-style-iw-c{
            max-width: 325px !important
        }
    }
    @media (max-width:400px) {
        .gm-style .gm-style-iw-c{
            min-width: 325px !important
        }
    }
</style>