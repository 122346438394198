<template>
    <div class="row" v-if="store.filters_.currency_range !== null || store.filters_.isOpen || store.filters_.hasDeals || store.filters_.rating > 0 || store.filters_.gender !== '' || store.filters_.distance > 0">
        <div class="col-12 pb-1 px-4">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-filter">
                        Clear all<a href="#" v-on:click.prevent="store.clearFilters">&#x2716;</a>
                    </li>
                    <li class="breadcrumb-filter" v-if="store.filters_.currency_range !== null">
                        {{store.filters_.currency_range}} <a href="#" v-on:click.prevent="store.filters_.currency_range = null">&#x2716;</a>
                    </li>
                    <li class="breadcrumb-filter" v-if="store.filters_.isOpen">
                        Open Now <a href="#" v-on:click.prevent="store.filters_.isOpen = false">&#x2716;</a>
                    </li>
                    <li class="breadcrumb-filter" v-if="store.filters_.hasDeals">
                        Today deals <a href="#" v-on:click.prevent="store.filters_.hasDeals = false">&#x2716;</a>
                    </li>
                    <li class="breadcrumb-filter" v-if="store.filters_.rating > 0">
                        {{store.filters_.rating}} Star<span v-if="store.filters_.rating > 1">s</span> <a href="#" v-on:click.prevent="store.filters_.rating = 0">&#x2716;</a>
                    </li>
                    <li class="breadcrumb-filter" v-if="store.filters_.gender !== ''">
                        {{store.filters_.gender}} <a href="#" v-on:click.prevent="store.filters_.gender = ''">&#x2716;</a>
                    </li>
                    <li class="breadcrumb-filter" v-if="store.filters_.distance > 0">
                        {{store.filters_.distance}} Miles <a href="#" v-on:click.prevent="store.filters_.distance = 0">&#x2716;</a>
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</template>
<script setup>
    import { useRoute } from 'vue-router'
    import { useSearchStore } from '@/stores/search'
    import { useDealsStore } from '@/stores/deals'
    const route = useRoute()
    let store;
    if (route.name === "AllDeals") {
        store = useDealsStore()
    }else{
        store = useSearchStore()
    }
</script>