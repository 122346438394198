<template>
	<section class="search-bar-content">
		<div class="container-fluid">
			<div class="search-box px-4 text-center">
				<div class="row">
					<!-- <div class="col-lg-3 col-md-3 d-none d-lg-block">
						<button v-on:click="filterIt('render','business')" type="button" class="btn search-bar-button " :class="render == 'business' ? 'active':''"><img src="@/assets/images/icons/at-bs.png" class="img-fluid" style="width:18px" />At Business</button>
						<button v-on:click="filterIt('render','client')" type="button" class="btn search-bar-button" :class="render == 'client' ? 'active':''"><img src="@/assets/images/icons/hs-call.png"  class="img-fluid" style="width:20px"/>My Location</button>
					</div> -->
					<div class="col-lg-3 col-md-3 d-none d-lg-block">
						<button v-on:click="store.filters_.render = 'business'" type="button" class="btn search-bar-button " :class="store.filters_.render === 'business' ? 'active':''"><img src="@/assets/images/icons/at-bs.png" class="img-fluid" style="width:18px" />At Business</button>
						<button v-on:click="store.filters_.render = 'client'" type="button" class="btn search-bar-button" :class="store.filters_.render === 'client' ? 'active':''"><img src="@/assets/images/icons/hs-call.png"  class="img-fluid" style="width:20px"/>My Location</button>
					</div>
					<div class="col-lg-7 col-12 mb-0 listing-search">
						<Search />
					</div>
					<div class="col-lg-2 col-md-2 mb-0 float-end d-none d-lg-block">
                        <select v-model="store.filters_.sort_by" class="form-select mt-2" style="font-weight: 500;color: #121212">
                            <option value="featured">Featured</option>
                            <option value="new">New Businesses</option>
                            <option value="top_rated">Exceptional Ratings</option>
                        </select>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script setup>
	import Search from '@/components/home/Search'
	import { useSearchStore } from '@/stores/search'
	const store = useSearchStore()
</script>