<template>
<div class="results-cont">
    <card-shimmer :is-loading="store.isLoading" :list-view="shimmerConfig.listView" :num-of-rows="shimmerConfig.noOfRows" />
    <div v-if="store.isLoading === false">
        <!-- <h3 class="allTitles"><span v-if="!$route.params.service">{{serviceFormate($route.params.service)}}</span> <span v-else>Businesses</span>  in {{locationFormate($route.params.location)}}</h3> -->
        <h4 class="allTitles" v-if="store.businessList.length > 0">All Businesses</h4>
        <router-link class="result-box d-block d-sm-flex" v-for="(business,index) in store.businessList" :key="index" :to="{name:'BusinessProfile',params:{slug:business.title_slug}}" target="_blank">
            <div class="salon-image">
                <img v-if="business.profile_pic" :src="business.profile_pic" class="img-fluid" />
                <img v-else src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg" class="img-fluid" />
            </div>
            <div class="salon-detail">
                <div class="salon-name">
                    <h4 class="mb-0">{{business.title}}</h4>
                </div>
                <div class="salon-rating d-flex">
                    <span style="padding-top: 7px;padding-right: 5px;"><b>{{business.rating}}</b></span>
                    <star-rating :rating="convertToNumbers(business.rating)" :title="business.rating" :round-start-rating="false" :inline="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating>
                    <span style="padding-top: 8px;padding-left: 8px">({{ business.total_reviews }})</span>
                </div>
                <div class="description" v-html="displayCurrencyRangeAndCategories(business.categories,business.min_price_length,business.max_price_length)"></div>
                <div class="opening-status" v-if="business.is_open > 0"><b>Open</b></div>
                <div class="closing-status" v-if="business.is_open === 0"><b>Closed</b></div>
                <div class="salon-address">{{business.neighborhood ? business.neighborhood+', ' : ''}} {{business.city}}, {{business.state}} <span v-if="business.distance > 0" class="ps-2"><fa icon="location-arrow" /> {{business.distance}} mi</span> </div>
                <!-- TODO: Mehran please make Est Waiting time and Queue Check functional -->
                <div class="date-time mt-sm-4 mt-1">
                    <span class="est-date">
                        <Tooltip :tooltipText="'Accepting Appointments'"><img src="@/assets/images/icons/calendar.png" /></Tooltip>
                    </span>

                    

                    <span class="est-time pt-sm-1" v-if="business.is_open === 1"> 
                        <Tooltip :tooltipText="'Queue Disabled'"  v-if=" business.user_settings  && business.user_settings.queue_status == 0"><img src="@/assets/images/icons/estm-time.png" style="width: 25px" class="queue_icon" /></Tooltip>

                        <Tooltip :tooltipText="'Accepting Live Queue'" v-if=" business.user_settings  && business.user_settings.queue_status == 1 && business.user_settings.queue_walkin == 0"><img src="@/assets/images/icons/estm-time.png" style="width: 25px" class="queue_icon" /></Tooltip>

                        <Tooltip :tooltipText="'Accepting Walk-in Queue'"  v-if=" business.user_settings &&  business.user_settings.queue_status == 1 && business.user_settings.queue_walkin == 1"><img src="@/assets/images/icons/estm-time.png" style="width: 25px" class="queue_icon" /></Tooltip>
                    </span>

                    <span class="est-time pt-sm-1" v-if="business.est_waiting_time &&  business.user_settings  && business.user_settings.queue_status == 1 && business.is_open === 1 ">
                        <Tooltip :tooltipText="'Professional with the shortest duration'">Est. wait time ({{business.est_waiting_time}})  </Tooltip>
                    </span>

                </div>
                <!-- <hr class="faded my-1 my-sm-2 d-none d-sm-block">
                <div class="my-sm-1 text-center d-none d-sm-block">
                    <span class="me-sm-2 me-1 next_available">Next Availability</span>
                    <a class="btn grey-button">Dec 21, 2021 4:30 PM</a>
                </div> -->
            </div>
            <!-- <hr class="faded my-1 my-sm-2 d-block d-sm-none">
            <div class="my-sm-1 text-center d-block d-sm-none">
                <span class="me-sm-2 me-1 next_available">Next Availability</span>
                <a class="btn grey-button">Dec 21, 2021 4:30 PM</a>
            </div> -->
        </router-link>
        <div class="load-more" v-if="store.load_more.value">
            <button class="btn btn-primary" type="button" :disabled="store.load_more.isLoading" v-on:click="loadMore">
                <span v-if="store.load_more.isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span v-if="store.load_more.isLoading"> Loading...</span>
                <span v-if="!store.load_more.isLoading"> Load More</span>
            </button>
        </div>
        <!-- No Business - Not Found Text -->
        <div v-if="store.businessList.length == 0">
            <h5 class="mt-5 mb-3 d-md-block d-none" >We didn't find a match for your search</h5>
            <h6 class="mt-5 mb-3 d-block d-sm-none" >We didn't find a match for your search</h6>
            <ul class="ps-4 fw-light not_found">
                <li>Try checking your spelling or using less specific keyword</li>
                <li>Search a different location</li>
                <li>Search any service or Business names</li>
            </ul>
            <div class="border p-4 rounded-3 d-flex justify-content-between align-items-center d-md-flex d-none">
                <div class="d-flex">
                    <div class="me-2">
                        <img src="@/assets/images/dashboard/search.svg" style="width: 50px">
                    </div>
                    <div>
                        <h5>Can’t find your favorite business?</h5>
                        <p class="text-muted mb-0">Please tell them about Ondaq</p>
                    </div>
                </div>
                <button class="ond-btn-p fs-6 send_a_text">Copy Link</button>
            </div>
            <div class="d-block d-md-none text-center mt-5">
                <h6 class="fw-normal">Can’t find your favorite business?</h6>
                <!-- <p class="text-muted mb-0">Please tell them about Ondaq</p> -->
                <button class="ond-btn-p fs-6 send_a_text">Send an invite</button>
                <p class="text-center mt-1" style="font-size: 12px">Charges may apply</p>
            </div>
            <!-- <p v-if="businessListing.length == 0">Sorry, we couldn't find any results for search. Try checking your spelling or using less specific keywords.</p>
            <p v-if="businessListing.length == 0" class="d-none d-md-block">Tell the business about Ondaq <a href="https://bravo.ondaq.com" class="btn default-button px-2 py-1">Copy Link</a></p>
            <p v-if="businessListing.length == 0" class="d-block d-md-none">Tell the business about Ondaq <a href="https://bravo.ondaq.com" class="btn default-button px-2 py-1">Send a text</a><small>Charges may apply</small></p>

            <h5 class="text-center mt-5" v-if="businessListing.length == 0">We didn’t find a match for your search</h5>
            <p class="text-center px-5" v-if="businessListing.length == 0">Hey sorry, we couldn’t display the results you’re searching. We’re guessing salons and beauty professionals in your neighborhood or city haven’t discovered us.</p>
            <p class="text-center px-5" v-if="businessListing.length == 0">Please send our link <a href="https://bravo.ondaq.com" class="btn default-button px-2 py-1">Copy</a> to any professional or salons around you. Try search another keyword ie. Service name or the business name. Thank you</p> -->
        </div>
    </div>
</div>
</template>

<script setup>
    import { ref } from 'vue'
    import StarRating from 'vue-star-rating'
    import Tooltip from "@/components/common/Tooltip.vue"
    import { useRoute } from 'vue-router'
    import { useSearchStore } from '@/stores/search'
    import CardShimmer from '@/components/shimmer/CardShimmer';
	const store = useSearchStore()
    const route = useRoute()
    const shimmerConfig = ref({
        listView: true,
        noOfRows: 3
    })
    if(window.matchMedia("(max-width: 767px)").matches){
        shimmerConfig.value = {
            listView: false,
            noOfRows: 2
        }
    }

    const convertToNumbers = (number) => {
        const decimalPart = number - Math.floor(number);
        // Check if the decimal part is greater than 0.5
        if (decimalPart > 0.5) {
            console.log("greater");
            return Math.floor(number * 2) / 2;
        } else if (decimalPart < 0.5) {
            return Math.ceil(number * 2) / 2;
        }else {
            console.log("actual");
            return number;
        } 
    }

    const displayCurrencyRangeAndCategories = (categories,min_len=0,max_len=0) => {
        let str = ''
        if (categories.length > 0) {
            for (let index = 0; (index < categories.length) && (index < 4); index++){
                str += ' <span> | </span> '+categories[index].title
            }
        }
        if (min_len !== null && min_len !== '' && min_len !== undefined && min_len !== 0) {
            str = "$".repeat(parseInt(min_len))+' - '+"$".repeat(parseInt(max_len)) +str;
        }else{
            str = str.slice(18)
        }
        return str
    }

    const loadMore = () => {
        store.page++
        store.load_more.isLoading = true
        store.getBusinesses(route.params,true)
    }
</script>
<style scoped>
.est-time{
    font-weight: 500;
}
@media screen and (max-width:576px) {
    .date-time img{
        width: 20px;
    }
    .queue_icon{
        width: 30px !important;
    }
    .description ,.salon-address{
        color: #333333;
    }
}
</style>