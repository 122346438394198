<template>
    <MainAppContainer>
        <main>
            <SearchBar />
            <div class="container-fluid">
                <div class="mobile-map-filter d-lg-none">
                    <span class="filter-btn">
                        <button type="button" class="btn sec-button" data-bs-toggle="modal" data-bs-target="#filterModal"><fa icon="sliders-h" /> Filter</button>
                    </span>
                    <span class="map-btn">
                        <button type="button" class="btn sec-button map_btn" data-bs-toggle="collapse" data-bs-target="#collapseMap" aria-expanded="false" aria-controls="collapseMap"><fa :icon="['far','map']" /> Map</button>
                    </span>
                </div>
            </div>
            <listing-section />
            <!-- Map -->
            <!-- <div class="collapse" id="collapseExample">
                <div class="mapColapse">
                    <Map />
                </div>
            </div>
            <section class="py-4">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <SelectedFilters />
                                <div class="col-lg-3 col-md-4 mb-0 d-none d-md-block">
                                    <Filters />
                                </div>
                                <div class="col-lg-9 col-md-8">
                                    <BusinessListing />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 mb-0 d-none d-md-block">
                            <div class="location-map">
                                <Map />
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->

            <!-- Mobile Filter Modal -->
            <div class="modal fade" id="filterModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="filterModalLabel">Filters</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <Filters />
                        </div>
                        <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-block search-button" data-bs-dismiss="modal">Apply</button>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- Filter Modal -->
        </main>
    </MainAppContainer>
</template>
<script setup>
    import MainAppContainer from '@/components/containers/MainAppContainer.vue'
    import SearchBar from '@/components/search/TopSearchBar.vue'
    import ListingSection from '@/components/search/ListingSection.vue'
    import Filters from '@/components/search/Filters.vue'

    import { watch } from "vue"
	import { useSearchStore } from '@/stores/search'
    import { useRoute } from 'vue-router'
    const route = useRoute()

	const store = useSearchStore()
    store.updateFilters(route)

    watch(() => store.filters_, (filters) => {
        store.page = 1
        store.updateFilters(route,filters)
    },{ deep: true });

</script>
<style scoped>
    .map_btn:active{
        background: gray;
        color: white;
    }
</style>