<template>
    <div class="filter-sidebar">
        <div class="filter-sidebar-title d-none d-lg-block">
            <h4 class="main-title">Filters</h4>
        </div>
        <div class="dolar-sign mt-1 mt-lg-4">
            <Tooltip :tooltipText="'Under $10'" style="width: 20%;" v-on:click="store.filters_.currency_range = '$'" :class="store.filters_.currency_range === '$' ? 'avtive' : ''">$</Tooltip>
            <Tooltip :tooltipText="'$10 - $99'" style="width: 25%;" v-on:click="store.filters_.currency_range = '$$'" :class="store.filters_.currency_range === '$$' ? 'avtive' : ''">$$</Tooltip>
            <Tooltip :tooltipText="'$100 - $999'" style="width: 25%;" v-on:click="store.filters_.currency_range = '$$$'" :class="store.filters_.currency_range === '$$$' ? 'avtive' : ''">$$$</Tooltip>
            <Tooltip :tooltipText="'$999 and over'" style="width: 30%;" v-on:click="store.filters_.currency_range = '$$$$'" :class="store.filters_.currency_range === '$$$$' ? 'avtive' : ''">$$$$</Tooltip>
        </div>
        <div class="open-status" v-if='route.name != "AllDeals"'>
            <h4 class="filter-title">Explore</h4>
            <div class="form-check">
                <!-- <input @change="filterIt('openNow',openNow)" v-model="openNow" true-value="Open now" false-value="Closed" class="form-check-input form-check-success" type="checkbox" name="openNow" id="openNow" /> -->
                <input v-model="store.filters_.isOpen" class="form-check-input form-check-success" type="checkbox" name="openNow" id="openNow" />
                <label class="form-check-label" for="openNow">
                    Open Now
                </label>
            </div>
            <div class="form-check">
                <!-- <input @change="filterIt('todayDeals',todayDeals)" v-model="todayDeals" true-value="Today deals" false-value="All deals" class="form-check-input form-check-success" type="checkbox" name="todayDeals" id="todayDeals" /> -->
                <input v-model="store.filters_.hasDeals" class="form-check-input form-check-success" type="checkbox" name="todayDeals" id="todayDeals" />
                <label class="form-check-label" for="todayDeals">
                    Today’s Deals
                </label>
            </div>
        </div>
        <div class="filter-box">
            <h4 class="filter-title">Ratings</h4>
            <star-rating :rating="parseInt(store.filters_.rating)" @update:rating="updateRating($event)" :star-size="25" :show-rating="false"></star-rating>
        </div>
        <div class="filter-box">
            <h4 class="filter-title">Gender</h4>
            <div class="filter-option">
                <div class="form-check">
                    <input type="radio" id="gany" value="" v-model="store.filters_.gender" class="form-check-input" />
                    <label class="form-check-label" for="gany">Any</label>
                </div>
                <div class="form-check">
                    <input type="radio" id="gmale" value="Male" v-model="store.filters_.gender" class="form-check-input" />
                    <label class="form-check-label" for="gmale">Male</label>
                </div>
                <div class="form-check">
                    <input type="radio" id="gfemale" value="Female" v-model="store.filters_.gender" class="form-check-input" />
                    <label class="form-check-label" for="gfemale">Female</label>
                </div>
                <div class="form-check">
                    <input type="radio" id="gother" value="Other" v-model="store.filters_.gender" class="form-check-input" />
                    <label class="form-check-label" for="gother">Others</label>
                </div>
            </div>
        </div>

        <div class="filter-box">
            <h4 class="filter-title">Distance</h4>
            <div class="filter-option">
                <div class="form-check">
                    <input v-model="store.filters_.distance" value="0" class="form-check-input" type="radio" id="distance1" />
                    <label class="form-check-label" for="distance1">Your Default Search</label>
                </div>
                <div class="form-check">
                    <input v-model="store.filters_.distance" value="5" class="form-check-input" type="radio" id="distance2" />
                    <label class="form-check-label" for="distance2">Within (5 mi.)</label>
                </div>
                <div class="form-check">
                    <input v-model="store.filters_.distance" value="10" class="form-check-input" type="radio" id="distance3" />
                    <label class="form-check-label" for="distance3">Within (10 mi.)</label>
                </div>
                <div class="form-check">
                    <input v-model="store.filters_.distance" value="25" class="form-check-input" type="radio" id="distance4" />
                    <label class="form-check-label" for="distance4">Within (25 mi.)</label>
                </div>
                <div class="form-check">
                    <input v-model="store.filters_.distance" value="50" class="form-check-input" type="radio" id="distance5" />
                    <label class="form-check-label" for="distance5">Within (50 mi.)</label>
                </div>
            </div>
        </div>







        <!-- <div class="filter-box">
            <h4 class="filter-title">Service Option</h4>
            <div class="filter-option">
                <div class="form-check">
                    <input v-on:click="filterIt('serviceOption','Any')" v-model="serviceOption" value="" class="form-check-input" type="radio" name="sopt" id="serviceOption1" />
                    <label class="form-check-label" for="serviceOption1">
                        Any
                    </label>
                </div>
                <div class="form-check">
                    <input v-on:click="filterIt('serviceOption','Appointment')" v-model="serviceOption" value="appointment" class="form-check-input" type="radio" name="sopt" id="serviceOption2" />
                    <label class="form-check-label" for="serviceOption2">
                        By Appointment
                    </label>
                </div>
                <div class="form-check">
                    <input v-on:click="filterIt('serviceOption','Queue')" v-model="serviceOption" value="queue" class="form-check-input" type="radio" name="sopt" id="serviceOption3" />
                    <label class="form-check-label" for="serviceOption3">
                        Queue
                    </label>
                </div>
            </div>
        </div> -->
        <!-- <div class="filter-box">
            <h4 class="filter-title">Special Offers</h4>
            <div class="filter-option">
                <div class="form-check">
                    <input v-on:click="filterIt('specialOffer','Any')" v-model="specialOffer" value="" class="form-check-input" type="radio" name="soff" id="specialOffer1" />
                    <label class="form-check-label" for="specialOffer1">
                        Any
                    </label>
                </div>
                <div class="form-check">
                    <input v-on:click="filterIt('specialOffer','zeroPackageDeal')" v-model="specialOffer" value="zeroPackageDeal" class="form-check-input" type="radio" name="soff" id="specialOffer2" />
                    <label class="form-check-label" for="specialOffer2">
                        Package Deal
                    </label>
                </div>
            </div>
        </div> -->

        <!-- <div class="filter-box">
            <h4 class="filter-title">Payment</h4>
            <div class="filter-option">
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="Payment1" />
                    <label class="form-check-label" for="Payment1">
                        Online payments
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="Payment2" checked />
                    <label class="form-check-label" for="Payment2">
                        Cash
                    </label>
                </div>
            </div>
        </div> -->

        <!-- <div class="filter-box">
            <h4 class="filter-title">Language</h4>
            <div class="filter-option">
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="language1" />
                    <label class="form-check-label" for="language1">English</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="language2" />
                    <label class="form-check-label" for="language2">Spanish</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="language3" />
                    <label class="form-check-label" for="language3">French</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="language4" />
                    <label class="form-check-label" for="language4">Chinese</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="language5" />
                    <label class="form-check-label" for="language5">African</label>
                </div>
            </div>
        </div> -->
        <!-- End filter Box -->
    </div>
</template>
<script setup>
    import StarRating from 'vue-star-rating'
    import { useRoute } from 'vue-router';
    import Tooltip from "@/components/common/Tooltip.vue";
	import { useSearchStore } from '@/stores/search'
    import { useDealsStore } from '@/stores/deals'
    const route = useRoute()

    let store;
    if (route.name === "AllDeals") {
        store = useDealsStore()
    }else{
        store = useSearchStore()
    }

    const updateRating = function (value){
        store.filters_.rating = value
    }
</script>

<style scoped>
    .main-title, .filter-title {
        color: #1c1c1c !important;
    }
    .form-check input, .form-check label{
        cursor: pointer;
    }
</style>